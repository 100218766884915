import React from 'react'

const InternshipPage = () => {
  return (
    <div className='internship-page'>
            
      
    </div>
  )
}

export default InternshipPage
